@charset "UTF-8";
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
nav,
section,
summary {
  display: block;
}

audio,
canvas,
video {
  display: inline-block;
}

audio:not([controls]) {
  display: none;
  height: 0;
}

[hidden],
template {
  display: none;
}

html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
}

html,
button,
input,
select,
textarea {
  font-family: sans-serif;
}

body {
  margin: 0;
}

a {
  background: transparent;
}
a:focus {
  outline: thin dotted;
}
a:hover, a:active {
  outline: 0;
}

h1 {
  font-size: 2em;
  margin: 0.67em 0;
}

h2 {
  font-size: 1.5em;
  margin: 0.83em 0;
}

h3 {
  font-size: 1.17em;
  margin: 1em 0;
}

h4 {
  font-size: 1em;
  margin: 1.33em 0;
}

h5 {
  font-size: 0.83em;
  margin: 1.67em 0;
}

h6 {
  font-size: 0.75em;
  margin: 2.33em 0;
}

abbr[title] {
  border-bottom: 1px dotted;
}

b,
strong {
  font-weight: bold;
}

dfn {
  font-style: italic;
}

mark {
  background: #ff0;
  color: #000;
}

code,
kbd,
pre,
samp {
  font-family: monospace, serif;
  font-size: 1em;
}

pre {
  white-space: pre;
  white-space: pre-wrap;
  word-wrap: break-word;
}

q {
  quotes: "“" "”" "‘" "’";
}

q:before, q:after {
  content: "";
  content: none;
}

small {
  font-size: 80%;
}

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline;
}

sup {
  top: -0.5em;
}

sub {
  bottom: -0.25em;
}

img {
  border: 0;
}

svg:not(:root) {
  overflow: hidden;
}

figure {
  margin: 0;
}

fieldset {
  border: 1px solid #c0c0c0;
  margin: 0 2px;
  padding: 0.35em 0.625em 0.75em;
}

legend {
  border: 0;
  padding: 0;
  white-space: normal;
}

button,
input,
select,
textarea {
  font-family: inherit;
  font-size: 100%;
  margin: 0;
  vertical-align: baseline;
}

button,
input {
  line-height: normal;
}

button,
select {
  text-transform: none;
}

button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  cursor: pointer;
}

button[disabled],
input[disabled] {
  cursor: default;
}

button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  padding: 0;
}

textarea {
  overflow: auto;
  vertical-align: top;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

input[type=search] {
  -webkit-appearance: textfield;
  -moz-box-sizing: content-box;
  -webkit-box-sizing: content-box;
  box-sizing: content-box;
}
input[type=search]::-webkit-search-cancel-button, input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
}
input[type=checkbox], input[type=radio] {
  box-sizing: border-box;
  padding: 0;
}

/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Icons             =
==================================*/
.ico {
  font-family: "skadden" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*==================================
=            Mixin Helpers             =
==================================*/
/*==================================
=            Icons             =
==================================*/
.ico {
  font-family: "skadden" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/*==================================
=            Load Fonts            =
==================================*/
@font-face {
  font-family: "UniversLTStd-CnObl";
  src: url("/assets/site/public/fonts/31E031_0_0.eot");
  src: url("/assets/site/public/fonts/31E031_0_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_0_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_0_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_0_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniversLTStd-BoldCnObl";
  src: url("/assets/site/public/fonts/31E031_1_0.eot");
  src: url("/assets/site/public/fonts/31E031_1_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_1_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_1_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_1_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniversLTStd-LightCn";
  src: url("/assets/site/public/fonts/31E031_2_0.eot");
  src: url("/assets/site/public/fonts/31E031_2_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_2_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_2_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_2_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniversLTStd-LightCnObl";
  src: url("/assets/site/public/fonts/31E031_3_0.eot");
  src: url("/assets/site/public/fonts/31E031_3_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_3_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_3_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_3_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniversLTStd-Condensed";
  src: url("/assets/site/public/fonts/31E031_4_0.eot");
  src: url("/assets/site/public/fonts/31E031_4_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_4_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_4_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_4_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "UniversLTStd-CondensedBold";
  src: url("/assets/site/public/fonts/31E031_5_0.eot");
  src: url("/assets/site/public/fonts/31E031_5_0.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/31E031_5_0.woff") format("woff"), url("/assets/site/public/fonts/31E031_5_0.woff2") format("woff2"), url("/assets/site/public/fonts/31E031_5_0.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Regular-Web.eot");
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Regular-Web.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Regular-Web.woff") format("woff"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Regular-Web.woff2") format("woff2"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Regular-Web.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web.eot");
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web.woff") format("woff"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web.woff2") format("woff2"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Bold-Web.eot");
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Bold-Web.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Bold-Web.woff") format("woff"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Bold-Web.woff2") format("woff2"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-Bold-Web.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: "Lyon Text Web";
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web.eot");
  src: url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web.eot?#iefix") format("embedded-opentype"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web.woff") format("woff"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web.woff2") format("woff2"), url("/assets/site/public/fonts/../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}
@font-face {
  font-family: "skadden";
  src: url("/assets/site/public/fonts/skadden.ttf?9c3a3a") format("truetype"), url("/assets/site/public/fonts/skadden.woff?9c3a3a") format("woff"), url("/assets/site/public/fonts/skadden.svg?9c3a3a#skadden") format("svg");
  font-weight: normal;
  font-style: normal;
}
.ico-link:before {
  content: "\e911";
}

.ico-upward-arrow:before {
  content: "\e90f";
}

.ico-external:before {
  content: "\e900";
}

.ico-lock:before {
  content: "\e901";
}

.ico-twitter:before {
  content: "\f099";
}

.ico-facebook:before {
  content: "\f09a";
}

.ico-linkedin:before {
  content: "\f0e1";
}

.ico-search:before {
  content: "\eb19";
}

.ico-chevron-bottom:before {
  content: "\f091";
}

.ico-chevron-left:before {
  content: "\f092";
}

.ico-chevron-right:before {
  content: "\f093";
}

.ico-cross:before {
  content: "\eae3";
}

.ico-minus:before {
  content: "\eb03";
}

.ico-plus:before {
  content: "\eb0a";
}

.ico-pdf:before {
  content: "\e902";
}

.ico-vcard:before {
  content: "\e903";
}

.ico-share:before {
  content: "\e907";
}

.ico-chevron-down-alt:before {
  content: "\e904";
}

.ico-chevron-up:before {
  content: "\e905";
}

.ico-email:before {
  content: "\e906";
}

.ico-download:before {
  content: "\e908";
}

.ico-up-arrow:before {
  content: "\e909";
}

.ico-location:before {
  content: "\e90b";
}

.ico-envelope:before {
  content: "\e90c";
}

.ico-home:before {
  content: "\e912";
}

.ico-chevron-down-thin:before {
  content: "\e913";
}

.ico-chevron-right-thin:before {
  content: "\e914";
}

.ico-headphones:before {
  content: "\e915";
}

body {
  font-size: 100%;
  font: normal 100% "UniversLTStd-Condensed", Arial, sans-serif;
  line-height: 1.5;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  color: #0e0e0e;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-style: normal;
  font-weight: normal;
  line-height: 1.2;
  margin: 0;
}

h1 {
  font-size: 3.0625em;
  line-height: 1.1;
}

h2 {
  font-size: 2.8125em;
  line-height: 1.1;
}

h3 {
  font-size: 1.75em;
}

h4 {
  font-size: 1.3125em;
}

h5 {
  font-size: 1.09375em;
}

.section-heading {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.8125em;
  line-height: 1.1;
  margin-bottom: 0.6222222222em;
}

.section-heading-spacing {
  font-family: "UniversLTStd-LightCn";
  font-size: 2.8125em;
  line-height: 1.1;
  margin-bottom: 1.2444444444em;
}

.panel-section-subheading,
.rte-subheading {
  display: block;
  font-family: "UniversLTStd-LightCn";
  font-size: 2.375em;
  line-height: 1.1;
  color: #626262;
  margin-bottom: 0.6222222222em;
}

.rte-subheading {
  font-size: 38px;
}

.section-subheading {
  font-size: 1.75em;
  line-height: 1.2;
  margin-bottom: 1em;
}
.section-subheading.small-margin {
  margin-bottom: 0.2em;
}

@media screen and (max-width: 47.9375em) {
  .section-heading {
    font-size: 2.25em;
  }

  .panel-section-subheading {
    font-size: 1.875em;
  }

  .rte-subheading {
    font-size: 30px;
  }
}
.is-disabled {
  cursor: auto;
  opacity: 0.5;
  user-select: none;
}

.is-active {
  color: #767676 !important;
}

.is-hidden,
.is-invalid-msg {
  display: none;
}

.form-field.is-invalid {
  /*
  .input, textarea,
  .select-dropdown-toggle  {
  	color: $color-brand-red !important;

  	@include placeholder {
  	  color: $color-brand-red !important;
  	}
  }
  */
}
.form-field.is-invalid .form-label,
.form-field.is-invalid .form-field-header {
  color: #e82011 !important;
}
.form-field.is-invalid .input,
.form-field.is-invalid textarea,
.form-field.is-invalid .select-dropdown-toggle {
  border-color: #e82011 !important;
}
.form-field.is-invalid .file-input-container .form-label {
  border-color: #e82011 !important;
}
.form-field.is-invalid .is-invalid-msg {
  display: block;
  color: #e82011;
}

.heading-checkbox.is-invalid .heading-checkbox__label {
  color: #e82011 !important;
  font-weight: bold;
}
.heading-checkbox.is-invalid .input {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input::-webkit-input-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input::-moz-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input:-moz-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input:-ms-input-placeholder {
  color: #e82011 !important;
}
.heading-checkbox.is-invalid .input {
  border-color: #e82011 !important;
}

.is-required:after {
  content: "*";
  margin-left: 2px;
}

.deceased:after {
  content: "*";
  color: #e82011;
  margin-left: 2px;
}

.deceased-label {
  color: #e82011;
  position: absolute;
  right: 1.3125em;
  bottom: 0.625em;
  font-family: "UniversLTStd-LightCn";
  font-size: 21px;
  font-style: italic;
}

.no-link:after {
  content: "" !important;
}

/*==========================================
=            Utility and States            =
==========================================*/
/*----------  Utilities  ----------*/
.util-pull-left {
  float: left;
}

.util-pull-right {
  float: right;
}

.util-reset-list {
  padding: 0;
  margin: 0;
  list-style: none;
}
.util-reset-list li {
  margin-bottom: 0;
}

.util-mobile-only {
  display: none;
}
@media screen and (max-width: 47.9375em) {
  .util-mobile-only {
    display: block;
  }
}

@media screen and (max-width: 47.9375em) {
  .util-mobile-hidden {
    display: none;
  }
}

@media screen and (max-width: 47.9375em) {
  .util-mobile-hidden {
    display: none;
  }
  .util-mobile-hidden.hide-important {
    display: none !important;
  }
}

@media screen and (max-width: 59.9375em) {
  .util-tablet-hidden {
    display: none;
  }
  .util-tablet-hidden.hide-important {
    display: none !important;
  }
}

.util-position-relative {
  position: relative;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  border: 0;
}

.sr-invisible-offset::before {
  display: block;
  content: " ";
  margin-top: -150px;
  height: 150px;
  visibility: hidden;
}

.centered {
  text-align: center;
  margin: 0 auto;
}

.bold {
  font-weight: bold;
}

.hidden {
  display: none !important;
}

* {
  box-sizing: border-box;
}
*:before, *:after {
  box-sizing: border-box;
}

figure {
  margin: 0;
}

main {
  display: block;
}

a {
  color: inherit;
  text-decoration: none;
  transition: color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
}
a:focus {
  outline: 1px dotted #e82011;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

p {
  margin: 0 0 1.625em;
}
p:last-child {
  margin-bottom: 0;
}

address,
em,
i {
  font-style: normal;
}

em,
i {
  font-family: "UniversLTStd-CnObl";
}

strong,
b {
  font-weight: normal;
  font-family: "UniversLTStd-CondensedBold";
}

#skip-to {
  height: 1px;
  width: 1px;
  clip: rect(0px, 0px, 0px, 0px);
  overflow: hidden;
  position: absolute;
  top: 0;
  margin: -1px;
}
#skip-to:focus, #skip-to:active {
  transition: background-color 0.35s cubic-bezier(0.86, 0, 0.07, 1);
  height: auto;
  width: auto;
  clip: auto;
  margin: 0;
  display: inline-block;
  overflow: visible;
  z-index: 510;
  background-color: white;
  padding: 0.5em 1em;
}

.margin-top-1rem {
  margin-top: 1rem !important;
}

.margin-bottom-0 {
  margin-bottom: 0 !important;
}

.padding-bottom-3em {
  padding-bottom: 3em !important;
}

.padding-bottom-0 {
  padding-bottom: 0 !important;
}

.margin-top-0 {
  margin-top: 0 !important;
}

.padding-top-3em {
  padding-top: 3em !important;
}

.margin-bottom-35px {
  margin-bottom: 35px !important;
}

@media screen and (max-width: 47.9375em) {
  .mobile-margin-top-0 {
    margin-top: 0 !important;
  }
}

.font-style-italic {
  font-style: italic;
}

:target:before {
  content: "";
  display: block;
  height: 1.90625em;
  margin: -1.90625em 0 0;
}

/*========================================
=            Layout Variables            =
========================================*/
.page-wrapper {
  max-width: 67.875em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 59.9375em) {
  .page-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}
.page-wrapper--wide {
  max-width: 75.875em;
}

.content-wrapper {
  max-width: 56.5625em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
}
@media screen and (max-width: 59.9375em) {
  .content-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}

.page-content-wrapper {
  max-width: 56.5625em;
  padding-left: 2.1875em;
  padding-right: 2.1875em;
  margin-left: auto;
  margin-right: auto;
  padding-top: 4.875em;
  margin-bottom: 8.125em;
}
@media screen and (max-width: 59.9375em) {
  .page-content-wrapper {
    padding-left: 1.375em;
    padding-right: 1.375em;
  }
}

.page-layout-spacing {
  padding-top: 4.875em;
  margin-bottom: 8.125em;
}
.page-layout-spacing.reduced-margin {
  margin-bottom: 4.0625em;
}

.no-introduction-spacing {
  padding-top: 4.875em;
}

.top-section-margin {
  margin-top: 3em;
}

.bottom-section-margin {
  margin-bottom: 3em;
}

body:before {
  content: "";
  display: block;
  padding-top: 3.8125em;
}

@media screen and (max-width: 47.9375em) {
  body:before {
    padding-top: 3.75em;
  }

  .page-content-wrapper,
.page-layout-spacing {
    padding-top: 3.125em;
    margin-bottom: 3.125em;
  }

  .no-introduction-spacing {
    padding-top: 3.125em;
  }

  .top-section-margin {
    margin-top: 2.25em;
  }

  .bottom-section-margin {
    margin-bottom: 2.25em;
  }
}