@import "manifest";

// /**
//  * @license
//  * MyFonts Webfont Build ID 3268657, 2016-08-15T08:36:40-0400
//  *
//  * The fonts listed in this notice are subject to the End User License
//  * Agreement(s) entered into by the website owner. All other parties are
//  * explicitly restricted from using the Licensed Webfonts(s).
//  *
//  * You may obtain a valid license at the URLs below.
//  *
//  * Webfont: UniversLTStd-CnObl by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/57-condensed-oblique/
//  * Copyright: Copyright &#x00A9; 1987, 1991, 1994, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
//  *
//  * Webfont: UniversLTStd-BoldCnObl by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/67-condensed-bold-oblique/
//  * Copyright: Copyright &#x00A9; 1987, 1991, 1998, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1998, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
//  *
//  * Webfont: UniversLTStd-LightCn by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/47-condensed-light/
//  * Copyright: Copyright &#x00A9; 1987, 1991, 1999, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1999, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
//  *
//  * Webfont: UniversLTStd-LightCnObl by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/47-condensed-light-oblique/
//  * Copyright: Copyright &#x00A9; 1987, 1991, 1999, 2002 Adobe Systems Incorporated.  All Rights Reserved. &#x00A9; 1981, 1999, 2002 Heidelberger Druckmaschinen AG. All rights reserved.
//  *
//  * Webfont: UniversLT-Condensed by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/57-condensed/
//  * Copyright:  Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1987, 1991, 1994, 1998, 2004 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Libra
//  *
//  * Webfont: UniversLT-CondensedBold by Linotype
//  * URL: http://www.myfonts.com/fonts/linotype/univers/67-condensed-bold/
//  * Copyright:  Part of the digitally encoded machine readable outline data for producing the Typefaces provided is copyrighted (c) 1987, 1991, 1994, 1998, 2004 Linotype Library GmbH, www.linotype.com. All rights reserved. This software is the property of Linotype Libra
//  *
//  *
//  * License: http://www.myfonts.com/viewlicense?type=web&buildid=3268657
//  * Licensed pageviews: 10,000,000
//  *
//  * © 2016 MyFonts Inc
// */



/*==================================
=            Load Fonts            =
==================================*/
@include font-face-family(
  $font-family-univers-condensed-oblique,
  "31E031_0_0",
  normal,
  normal
);
@include font-face-family(
  $font-family-univers-condensed-bold-oblique,
  "31E031_1_0",
  normal,
  normal
);
@include font-face-family(
  $font-family-univers-condensed-light,
  "31E031_2_0",
  normal,
  normal
);
@include font-face-family(
  $font-family-univers-condensed-light-oblique,
  "31E031_3_0",
  normal,
  normal
);
@include font-face-family(
  $font-family-univers-condensed,
  "31E031_4_0",
  normal,
  normal
);
@include font-face-family(
  $font-family-univers-condensed-bold,
  "31E031_5_0",
  normal,
  normal
);
@include font-face-family(
  "Lyon Text Web",
  "../../../../assets-careers/public/fonts/LyonText-Regular-Web",
  normal,
  normal
);
@include font-face-family(
  "Lyon Text Web",
  "../../../../assets-careers/public/fonts/LyonText-RegularItalic-Web",
  normal,
  italic
);
@include font-face-family(
  "Lyon Text Web",
  "../../../../assets-careers/public/fonts/LyonText-Bold-Web",
  bold,
  normal
);
@include font-face-family(
  "Lyon Text Web",
  "../../../../assets-careers/public/fonts/LyonText-BoldItalic-Web",
  bold,
  italic
);

// Skadden IconMoon Set
@include icon-face-family("skadden", $ico-font-path);

.#{$ico-css-prefix}-link:before {
  content: "\e911";
}
.#{$ico-css-prefix}-upward-arrow:before {
  content: "\e90f";
}
.#{$ico-css-prefix}-external:before {
  content: "\e900";
}
.#{$ico-css-prefix}-lock:before {
  content: "\e901";
}
.#{$ico-css-prefix}-twitter:before {
  content: "\f099";
}
.#{$ico-css-prefix}-facebook:before {
  content: "\f09a";
}
.#{$ico-css-prefix}-linkedin:before {
  content: "\f0e1";
}
.#{$ico-css-prefix}-search:before {
  content: "\eb19";
}
.#{$ico-css-prefix}-chevron-bottom:before {
  content: "\f091";
}
.#{$ico-css-prefix}-chevron-left:before {
  content: "\f092";
}
.#{$ico-css-prefix}-chevron-right:before {
  content: "\f093";
}
.#{$ico-css-prefix}-cross:before {
  content: "\eae3";
}
.#{$ico-css-prefix}-minus:before {
  content: "\eb03";
}
.#{$ico-css-prefix}-plus:before {
  content: "\eb0a";
}
.#{$ico-css-prefix}-pdf:before {
  content: "\e902";
}
.#{$ico-css-prefix}-vcard:before {
  content: "\e903";
}
.#{$ico-css-prefix}-share:before {
  content: "\e907";
}
.#{$ico-css-prefix}-chevron-down-alt:before {
  content: "\e904";
}
.#{$ico-css-prefix}-chevron-up:before {
  content: "\e905";
}
.#{$ico-css-prefix}-email:before {
  content: "\e906";
}
.#{$ico-css-prefix}-download:before {
  content: "\e908";
}
.#{$ico-css-prefix}-up-arrow:before {
  content: "\e909";
}
.#{$ico-css-prefix}-location:before {
  content: "\e90b";
}
.#{$ico-css-prefix}-envelope:before {
  content: "\e90c";
}
.#{$ico-css-prefix}-home:before {
  content: "\e912";
}
.#{$ico-css-prefix}-chevron-down-thin:before {
  content: "\e913";
}
.#{$ico-css-prefix}-chevron-right-thin:before {
  content: "\e914";
}
.#{$ico-css-prefix}-headphones:before {
  content: "\e915";
}
